import { render, staticRenderFns } from "./Conversations.vue?vue&type=template&id=203697ae&scoped=true&"
import script from "./Conversations.vue?vue&type=script&lang=js&"
export * from "./Conversations.vue?vue&type=script&lang=js&"
import style0 from "./Conversations.vue?vue&type=style&index=0&id=203697ae&lang=css&scoped=true&"
import style1 from "./Conversations.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "203697ae",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VChip,VDataTable,VIcon,VListItem,VListItemContent,VListItemSubtitle,VListItemTitle,VRow,VSwitch})
