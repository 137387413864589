<template>
  <div class="conversations">
    <div class="unread-switch">
      <v-switch label="Unread only" color="primary" class="v-input--reverse" v-model="unreadOnly"></v-switch>
    </div>
    <v-data-table
      v-if="conversations && partner && partnerView && renderComponent"
      fixed-header
      disable-sort
      :height="height"
      :loading="loading"
      :headers="headers"
      :items="conversations"
      item-key="index"
      class="grey lighten-4 elevation-0 row-pointer"
      :footer-props="footerProps"
      hide-default-footer
      @click:row="navToMessages"
      :item-class="rowClass"
      no-data-text
      no-results-text
    >
      <template v-slot:item.patient="{ item }">
        <v-list-item :key="item.key" class="pa-0">
          <v-list-item-content v-if="item">
            <v-list-item-title class="body-2">
              {{ item.patient.firstName }}
              <span class="font-weight-bold">{{ item.patient.lastName }}</span>
              <span
                v-if="item.patient.ownerPatientID"
                class="grey--text ml-1"
              >{{ item.patient.ownerPatientID }}</span>
            </v-list-item-title>
            <v-list-item-subtitle class="caption">
              {{ item.patient.age }} {{ item.patient.shortGender }} ∙ {{ item.patient.displayBirthdate
              }}
              <span
                v-if="item.patient.smsPhone"
              >∙ {{ item.patient.smsPhone }}</span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>

      <template v-slot:item.message="{ item }">
        <v-row no-gutters class="py-2 pl-4 pr-2">
          <div v-if="item.unread" class="font-weight-bold">
            <v-chip
              small
              label
              color="grey lighten-3"
              class="mr-1"
            >{{item.lastMessage.sender.displayName}}</v-chip>
            {{item.lastMessage.content}}
          </div>
          <div v-else>
            <v-chip
              small
              label
              color="grey lighten-3"
              class="mr-1"
            >{{item.lastMessage.sender.displayName}}</v-chip>
            <span class="body-2">{{item.lastMessage.content}}</span>
          </div>
        </v-row>
      </template>

      <template v-slot:item.timestamp="{ item }">
        <div
          v-if="item.lastMessage.created"
          class="text-no-wrap caption"
        >{{item.lastMessage.created | datetime}}</div>
      </template>
    </v-data-table>

    <div
      v-if="conversations && conversations.length === 0"
      class="convos-empty d-flex align-center justify-center"
    >
      <div class="pt-8 pb-6 headline font-weight-light">
        <v-icon size="64" color="grey lighten-1">mdi-message-text</v-icon>
        <div class="text--center">No conversations found</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import moment from 'moment-timezone'

const itemsPerPage = 200

const data = {
  loading: true,
  partnerView: true,
  unreadOnly: false,
  footerProps: {
    itemsPerPageOptions: [itemsPerPage],
    disableItemsPerPage: true,
  },
  renderComponent: true,
  conversations: null,
}

const dateTimeFilter = val => {
  let now = new Date()
  let today = moment(now).format('MM/DD/YYYY')
  let ts = moment(val).format('MM/DD/YYYY')
  if (today === ts) {
    return moment(val).format('h:mm a')
  }
  return moment(val).format('MMM D')
}

const tableHeaders = [
  {
    text: 'Patient',
    value: 'patient',
    width: 110,
  },
  {
    text: 'Message',
    value: 'message',
  },
  {
    text: '',
    value: 'timestamp',
    align: 'right',
  },
]

function getPatient(patientId) {
  let patients = this.enrolledPatients || []
  for (let i = 0; i < patients.length; i++) {
    if (patients[i]._id === patientId) {
      return patients[i]
    }
  }
  return {}
}

function rowClass(item) {
  if (item.unread) {
    return 'white row-class'
  }
  return 'grey lighten-4 row-class'
}

function navToMessages(item) {
  this.$router.push({
    name: 'Messages',
    params: { id: item.patient._id },
  })
}

function populateConvosWithPatients(conversations) {
  let patients = {}
  for (let i = 0; i < conversations.length; i++) {
    let patientID = conversations[i].patientID
    let patient = patients[patientID]
    if (!patient) {
      patient = this.getPatient(patientID)
    }
    conversations[i].patient = patient
  }
}

async function fetchConversations() {
  this.loading = true
  let conversations = await this.getConversations({
    orgId: this.partner._id,
    unreadOnly: this.unreadOnly,
    pageSize: itemsPerPage,
    pageToken: '',
  })

  this.populateConvosWithPatients(conversations)
  this.conversations = conversations
  this.loading = false
}

function mounted() {
  if (this.enrolledPatients.length) {
    this.fetchConversations()
  }
}

export default {
  props: ['height'],
  data: () => Object.assign({}, data),
  filters: {
    datetime: dateTimeFilter,
  },
  computed: {
    ...mapState('auth', ['partner']),
    ...mapState('conversation', ['unreadConversationsCount']),
    ...mapState('taskboard', ['enrolledPatients']),
    headers() {
      return tableHeaders
    },
  },
  methods: {
    ...mapActions('conversation', ['getConversations']),
    getPatient,
    rowClass,
    navToMessages,
    populateConvosWithPatients,
    fetchConversations,
  },
  watch: {
    async enrolledPatients() {
      this.fetchConversations()
    },
    async unreadOnly() {
      this.fetchConversations()
    },
    async unreadConversationsCount() {
      this.fetchConversations()
    },
  },
  mounted,
}
</script>

<style lang="css" scoped>
.convos-empty {
  position: absolute;
  top: 40%;
  left: 50%;
  width: 80%;
  max-width: 640px;
  transform: translate(-50%, -50%);
}

.row-pointer >>> tbody tr:hover {
  cursor: pointer;
  background-color: #eeeeee !important;
}
</style>

<style lang="scss">
.conversations {
  .row-class {
    vertical-align: top;
  }
  .row-class td:last-child div {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  .unread-switch {
    position: absolute;
    top: -5px;
    right: 14px;
    z-index: 4;
  }

  .v-input--reverse .v-input__slot {
    flex-direction: row-reverse;
    justify-content: flex-end;
    .v-application--is-ltr & {
      .v-input--selection-controls__input {
        margin-right: 0;
        margin-left: 8px;
      }
    }
    .v-application--is-rtl & {
      .v-input--selection-controls__input {
        margin-left: 0;
        margin-right: 8px;
      }
    }
  }

  .v-label {
    font-size: 12px;
    font-weight: bold;
  }

  .v-input--expand .v-input__slot {
    .v-label {
      display: block;
      flex: 1;
    }
  }
}
</style>